
import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator'
import Util from '../../../lib/util'
import AbpBase from '../../../lib/abpbase'
import Modal from '../../../components/Modal.vue'
import BaseButton from '@/components/BaseButton.vue'
import BaseSwitch from '@/components/Inputs/BaseSwitch.vue'
import Email from '@/store/entities/OZONE/email'
@Component({
  components: { Modal, BaseButton, BaseSwitch }
})
export default class EditEmail extends AbpBase {
  name: 'edit-estados-participacion'
  @Prop({ type: Boolean, default: false }) value: boolean
  @Prop({ type: Boolean, default: false }) editing: boolean
  email: Email = new Email()
  title: String = ''
  disabled: boolean = false

  created() {}
  async save() {
    if (this.checkFormValidity()) {
      if (this.editing) {
        await this.$store.dispatch({
          type: 'email/update',
          data: this.email
        })
      } else {
        await this.$store.dispatch({
          type: 'email/create',
          data: this.email
        })
      }

      this.$emit('save-success')
      this.$emit('input', false)
      this.resetFields()
    }
  }
  close() {
    this.$emit('input', false)
    this.resetFields()
  }
  shown() {
    if (this.editing) {
      this.email = Util.extend(true, {}, this.$store.state.email.editEmail)
      this.title = 'Editar email'
    }
    else {
      this.title = 'Crear nuevo email'
    }

    this.setDisabled()
  }
  checkFormValidity() {
    const valid = (this.$refs.emailForm as any).checkValidity()
    return valid
  }
  resetFields() {
    this.email = new Email()
  }

  setDisabled() {
    this.disabled = !abp.auth.isAnyGranted('OZONE.Emails.Edit', 'OZONE.Emails.Create', 'OZONE.Emails.Delete')
  }

  emailRule = {
    defaultFromAddress: {
      required: { required: true, email: true, min: 2 },
      message: this.L('ThisFieldIsRequired', undefined, this.L('DefaultFromAddress')),
      placeholder: 'Direccion de origen',
      trigger: 'blur'
    },
    defaultFromDisplayName: {
      required: { required: true },
      message: this.L('ThisFieldIsRequired', undefined, this.L('DefaultFromDisplayName')),
      placeholder: 'Nombre visible',
      trigger: 'blur'
    },
    host: {
      required: { required: true },
      message: this.L('ThisFieldIsRequired', undefined, this.L('Host')),
      placeholder: this.L('Host'),
      trigger: 'blur'
    },
    port: {
      required: { required: true, numeric: true },
      message: this.L('ThisFieldIsRequired', undefined, this.L('Port')),
      placeholder: 'Puerto',
      trigger: 'blur'
    },
    userName: {
      required: { required: true },
      message: this.L('ThisFieldIsRequired', undefined, this.L('UserName')),
      placeholder: this.L('UserName'),
      trigger: 'blur'
    },
    password: {
      required: { required: true },
      message: this.L('ThisFieldIsRequired', undefined, this.L('Password')),
      placeholder: this.L('Password'),
      trigger: 'blur',
      type: 'password'
    },
    domain: {
      required: { required: true },
      message: this.L('ThisFieldIsRequired', undefined, this.L('Domain')),
      placeholder: 'Dominio',
      trigger: 'blur'
    },
    enableSsl: {
      required: { required: true },
      message: this.L('ThisFieldIsRequired', undefined, this.L('EnableSsl')),
      placeholder: this.L('EnableSsl'),
      trigger: 'blur',
      type: 'boolean'
    },
    useDefaultCredentials: {
      required: { required: true },
      message: this.L('ThisFieldIsRequired', undefined, this.L('UseDefaultCredentials')),
      placeholder: this.L('UseDefaultCredentials'),
      trigger: 'blur',
      type: 'boolean'
    }
  }
}
