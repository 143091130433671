
import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator'
import Util from '@/lib/util'
import AbpBase from '@/lib/abpbase'
import PageRequest from '@/store/entities/page-request'
import EditEmail from './edit-email.vue'
import PeopleFind from "../../../components/People/people-find.vue";

class PageEmailRequest extends PageRequest {
  keyword: string
}

@Component({
  components: {
    EditEmail,
    PeopleFind
  }
})
export default class Email extends AbpBase {
  //filters
  pagerequest: PageEmailRequest = new PageEmailRequest()
  creationTime: Date[] = []

  createModalShow: boolean = false
  editModalShow: boolean = false
  get list() {
    return this.$store.state.email.list
  }
  get loading() {
    return this.$store.state.email.loading
  }
  create() {
    this.createModalShow = true
  }
  editRow(item, index, button) {
    this.$store.commit('email/edit', item)
    this.edit()
  }
  deleteRow(item, index, button) {
    this.$bvModal
      .msgBoxConfirm(this.L('¿Desea eliminar este email?'), {
        //title: this.L('Tips'),
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: this.L('Sí'),
        cancelTitle: this.L('No'),
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
      .then(async (value) => {
        if (value) {
          await this.$store.dispatch({
            type: 'email/delete',
            data: item
          })
          await this.getpage()
        }
      })
  }
  edit() {
    this.editModalShow = true
  }

  pageChange(page: number) {
    this.$store.commit('email/setCurrentPage', page)
    this.getpage()
  }
  pagesizeChange(pagesize: number) {
    this.$store.commit('email/setPageSize', pagesize)
    this.getpage()
  }
  async getpage() {
    this.pagerequest.maxResultCount = this.pageSize
    this.pagerequest.skipCount = (this.currentPage - 1) * this.pageSize
    
    //filters

    await this.$store.dispatch({
      type: 'email/getAll',
      data: this.pagerequest
    })
  }
  get pageSize() {
    return this.$store.state.email.pageSize
  }
  get totalCount() {
    return this.$store.state.email.totalCount
  }
  get currentPage() {
    return this.$store.state.email.currentPage
  }
  set currentPage(page) {
    this.$store.commit('email/setCurrentPage', page)
    this.getpage()
  }
  columns = [
    {
      title: this.L('Direccion Origen'),
      dataIndex: 'defaultFromAddress',
      thClass: 'w-auto',
      tdClass: 'w-auto'
    },
    {
      title: this.L('Nombre Visible'),
      dataIndex: 'defaultFromDisplayName',
      thClass: 'w-auto',
      tdClass: 'w-auto'
    },
    {
      title: this.L('Host'),
      dataIndex: 'host',
      thClass: 'w-auto',
      tdClass: 'w-auto'
    },
    {
      title: this.L('Puerto'),
      dataIndex: 'port',
      thClass: 'w-auto',
      tdClass: 'w-auto'
    },
    {
      title: this.L('Usuario'),
      dataIndex: 'userName',
      thClass: 'w-auto',
      tdClass: 'w-auto'
    },
    {
      title: this.L('Dominio'),
      dataIndex: 'domain',
      scopedSlots: { customRender: "status" },
      thClass: 'w-auto',
      tdClass: 'w-auto'
    },
    {
      title: this.L('SSL'),
      dataIndex: 'enableSsl',
      scopedSlots: { customRender: "ssl" },
      thClass: 'w-auto',
      tdClass: 'w-auto'
    },

    {
      title: this.L('Fecha Creacion'),
      dataIndex: 'creationTime',
      formatter: (value, key, item) => {
        return new Date(value).toLocaleDateString('es-ES')
      },
      thClass: 'text-center w-25',
      tdClass: 'text-center w-25'
    },
   {
      scopedSlots: { customRender: "actions" },
      title: "Acciones",
      fixed: "right",
    },
  ]

  async created() {
    this.getpage()
    await this.$store.dispatch({
      type: 'email/getAll'
    })
  }

    pagination: any = {};
  findModalShow: boolean = false;

  searchDefinition: {}[] = [
    {
      fieldName: "Nombre",
      fieldType: "text",
      fieldBind: "nombre",
      value: "",
    }
  ];

  handleTableChange = async (pagination, filters, sorter) => {
    this.$store.commit(
      "subEstadoParticipacion/setCurrentPage",
      pagination.current
    );
    //this.getpage();

    await this.getpage();
  };

  async hide() {
    this.findModalShow = false;
  }
  async find() {
    this.findModalShow = true;
  }

  getSearch = async (model) => {
    model.forEach((element) => {
      if (element.value) {
        this.pagerequest[element.fieldBind] = element.value;
      } else {
        delete this.pagerequest[element.fieldBind];
      }
    });

    //this.pagerequest.idcampaña =;
    await this.getpage();
    this.findModalShow = false;
  };
}
